import React from "react"
import Styles from "./featureBoxItem.module.scss"

const FeatureBoxItem = ({title, description, imgSrc, alt}) => (
  <div className={`column is-one-third ${Styles.item}`}>
    <img className={Styles.itemImage} src={imgSrc} alt={alt} />
    <h4 className={`is-size-5 is-size-6-mobile has-text-weight-bold ${Styles.itemTitle}`}>{title}</h4>
    <p className="is-size-6 is-size-7-mobile">{description}</p>
  </div>
)

export default FeatureBoxItem