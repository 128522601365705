import React from "react"

import Layout from "src/components/layout"
import Seo from "src/components/seo"
import Header from "src/components/header"
import Footer from "src/components/footer"
import Hero from "src/components/unit/hero"
import Whats from "src/components/unit/whats"
import Feature from "src/components/unit/feature"

const IndexPage = () => (
  <Layout>
    <Seo />
    <Header />
    <main>
      <Hero />
      <Whats />
      <Feature />
    </main>
    <Footer />
  </Layout>
)

export default IndexPage
