import React from "react"
import Header from "src/components/section/header"
import Title from "src/components/section/title"
import Copy from "src/components/section/salescopy"
import Image from "src/components/section/image"
import Wrap from "src/components/wrap"
import Spacer from "src/components/spacer"
import image from "src/images/unit_hero@2x.png"

const Caption = () => (
  <Header>
    <Title positionStyle="has-text-centered">配信ユニット</Title>
    <Copy positionStyle="has-text-centered">
      <Wrap><span>natadeCOCOスポットでの</span><span>コンテンツ配信専用サーバ</span><span>「配信ユニット」のご紹介です。</span></Wrap>
    </Copy>
    <Spacer isHalf />
    <Image src={image} alt="配信ユニットを紹介する人" />
  </Header>
)

export default Caption