import React from "react"
import Subsection from "src/components/section/sub"
import Button from "src/components/LinkedButton"
import Spacer from "src/components/spacer"

const ButtonComponent = ({buttonLabel, buttonTo}) => (
  <div className="has-text-centered">
    <Button to={buttonTo} title={buttonLabel} />
  </div>
)

const FeatureBox = ({ title, titleIcon, buttonLabel, buttonTo, children }) => {
  const buttonComponent = (buttonTo && buttonTo) ? <ButtonComponent buttonLabel={buttonLabel} buttonTo={buttonTo} /> : null;

  return (
    <div className="box is-shadowless">
      <Subsection title={title} icon={titleIcon}>
        <div className="columns has-text-centered">
          {children}
        </div>
      </Subsection>
      {buttonComponent && <Spacer isHalf />}
      {buttonComponent}
    </div>
  )
}

export default FeatureBox