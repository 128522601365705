import React from "react"
import Styles from "./feature.module.scss"
import Mainsection, { MainsectionText } from "src/components/section/main"
import FeatureBox from "src/components/featureBox"
import FeatureBoxItem from "src/components/featureBoxItem"
import Spacer from "src/components/spacer"
import Cloud1Img from "src/images/unit_cloud_1@2x.png"
import Cloud2Img from "src/images/unit_cloud_2@2x.png"
import Cloud3Img from "src/images/unit_cloud_3@2x.png"
import Edge1Img from "src/images/unit_edge_1@2x.png"
import Edge2Img from "src/images/unit_edge_2@2x.png"

const UnitFeatureBox = ({type, typeString, icon, children}) => {
  const titleLabel = `${typeString}版の特徴`
  const buttonLabel = `${typeString}版プランを見る`
  const buttonTo = `/price#price_${type}`

  return (
    <FeatureBox
      title={titleLabel}
      titleIcon={icon}
      buttonLabel={buttonLabel}
      buttonTo={buttonTo}>
      {children}
    </FeatureBox>
  )
}

const Example = ({index, title, icon}) => (
  <div className={`box is-shadowless has-background-success ${Styles.isVcentered} ${Styles.example}`}>
    <div className="columns is-vcentered">
      <div className={`column is-paddingless is-narrow ${Styles.iconArea}`}>
        <span className="icon is-medium"><i className={`${icon} fa-2x`} /></span>
      </div>
      <div className="column is-1 is-hidden-mobile"></div>
      <div className={`column is-paddingless has-text-left has-text-centered-mobile ${Styles.textArea}`}>
        <p className={`is-rounded has-text-white has-background-primary ${Styles.index}`}>連携例{index}</p>
        <p className={`has-text-weight-bold ${Styles.exsamples}`}>{title}</p>
      </div>
    </div>
  </div>
)

const Feature = () => (
  <div id="unit_feature_cloud">
    <Mainsection title="クラウド版とエッジ版の違いは？">
      <MainsectionText>
        <p>クラウド版は物理的なサーバが不要で、導入も簡単。すぐにご利用を始められます。</p>
        <p>また、エッジ版はローカルネットワークに専用サーバを設置するため、より高度なユースケースにおいてもご活用いただけます。</p>
      </MainsectionText>

      <Spacer />

      <UnitFeatureBox type="cloud" typeString="クラウド" icon="fas fa-cloud">
        <FeatureBoxItem
          title={<span>Wi-Fi-があれば<br />すぐ使える</span>}
          description={<span>申し込みから約１０分で<br />natadeCOCOスポットに変身</span>}
          imgSrc={Cloud1Img}
          alt="Wi-Fiがあればすぐ使える"
        />
        <FeatureBoxItem
          title={<span>持ち運びが<br />かんたん</span>}
          description={<span>Wi-Fiルータの移動のみで<br />いろんな場所がスポットになる</span>}
          imgSrc={Cloud2Img}
          alt="持ち運びがかんたん"
        />
        <FeatureBoxItem
          title={<span>まずはクラウド版で<br />お試しを！</span>}
          description={<span>気軽にお試しいただける<br />プランをご用意しております</span>}
          imgSrc={Cloud3Img}
          alt="まずはクラウド版でお試しを！"
        />
      </UnitFeatureBox>

      <Spacer />

      <UnitFeatureBox type="edge" typeString="エッジ" icon="fas fa-server">
        <FeatureBoxItem
          title={<span>リッチコンテンツも<br />高速配信</span>}
          description={<span>インターネットを経由せず<br />高速なWi-Fi環境にもぴったり</span>}
          imgSrc={Edge1Img}
          alt="リッチコンテンツも高速配信"
        />
        <FeatureBoxItem
          title={<span>アイデア次第で<br />さまざまなコラボ</span>}
          description={<span>IoTデバイスと連携して<br />これまでになかった体験を</span>}
          imgSrc={Edge2Img}
          alt="アイデア次第でさまざまなコラボ"
        />
        <div className="column is-one-third">
          <div className={`is-flex ${Styles.examples}`}>
            <Example
              index={1}
              title="デジタルサイネージ"
              icon="fas fa-tv"
            />
            <Example
              index={2}
              title="POSシステム"
              icon="fas fa-cash-register"
            />
            <Example
              index={3}
              title="Webカメラや音響設備"
              icon="fas fa-volume-up"
            />
          </div>
        </div>
      </UnitFeatureBox>
      <Spacer isHalf />
    </Mainsection>
  </div>
)

export default Feature
