import React from "react"
import Styles from "./whats.module.scss"
import Mainsection, { MainsectionText } from "src/components/section/main"
import AboutImg from "src/images/unit_about@2x.png"

const Whats = () => {
  const image = (
    <figure className={`image ${Styles.isFullOnMobile}`}>
      <img src={AboutImg} alt="配信ユニットとnatadeCOCOスポットの関係" />
    </figure>
  )

  return (
    <Mainsection title="配信ユニットとは？">
      <div className="columns">
        <div className="column is-hidden-tablet has-text-centered">{image}</div>
        <div className="column">
          <MainsectionText>
            <p>配信ユニットとは、natadeCOCOスポットでコンテンツを配信するための専用サーバです。</p>
            <p>どこでも使えるクラウド版と、斬新なアイデアも実現できるエッジ版の２タイプをご用意しております。</p>
          </MainsectionText>
        </div>
        <div className={`column is-hidden-mobile has-text-centered ${Styles.isVcentered}`}>{image}</div>
      </div>
    </Mainsection>
  )
}

export default Whats
